import React from "react";
import styled from "styled-components";
import {
  screen,
} from "../variables/variables";

const Wrapper = styled.div`
    width: 100%;
  .listBox {
    @media ${screen.small} {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
        width: 100%;
    }
      
      .stick-border {
          border-left: 1px solid #fff;
      }
    
    .title {
      font-size: 30px;
      font-weight: bold;
      line-height: 40px;
      color: #ffffff;
      margin-bottom: 25px;
      superscript {
          font-size: 15px;
          font-weight: bold;
          line-height: 40px;
      }
    }
    .dep {
      font-size: 20px;
      font-weight: normal;
      line-height: 30px;
      color: #ffffff;
      margin-bottom: 25px;
    }
    
    .question-block {
      max-width: 585px;
    }
  }
`;

const Questions = ({ data }) => {
  return (
    <Wrapper role="listBox">
      <div className={'listBox'}>
        {
          data.map((item, index) => {
            return (
              <>
                {index !== 0 && <div className={"stick-border"} />}
                <div key={index} className={'question-block'}>
                  <div className={'title'} dangerouslySetInnerHTML={{ __html: item.question }} />
                  <div className={'dep'}>{item.answer}</div>
                </div>
              </>
            )
          })
        }
      </div>
    </Wrapper>
  );
};

export default Questions;
