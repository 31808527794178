// reusable on all page
import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { mainWhite, screen } from "../variables/variables";
import InnerWrapper from "../inner-wrapper/inner-wrapper";
import Button from "../button/button";

const Wrapper = styled.header`
  background-image: ${({ bgImageUrlMobile, bgImageUrl }) =>
  bgImageUrlMobile ? `url(${bgImageUrlMobile})` : `url(${bgImageUrl})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 60px 0 0 0;
  position: relative;
  @media ${screen.xsmall} {
    background-image: ${({ bgImageUrl }) => `url(${bgImageUrl})`};
    padding: 90px 0 0 0;
  }

  ${({ noOverlay }) =>
    !noOverlay &&
    css`
      &::after {
        content: "";
        background: rgba(30, 30, 30, 0.7);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
      }
    `}

  .txt-container {
    max-width: ${({ textWidth }) => (textWidth ? `${textWidth}px` : "650px")};
    min-height: ${({ isWithButton }) => (isWithButton ? "380px" : "310px")};
    color: ${mainWhite};
    margin: 0 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: flex-end;
    position: relative;
    z-index: 2;
    text-align: left;
    @media ${screen.xsmall} {
      max-height: 750px;
      height: ${({ isWithButton }) =>
        isWithButton ? "calc(450px + 18vw)" : "calc(400px + 10vw)"};
    }

    .title {
      font-size: 1.75rem;
      font-weight: 700;
      @media ${screen.xsmall} {
        font-size: 2.85rem;
      }
      @media ${screen.small} {
        font-size: 3.05rem;
      }
      @media ${screen.medium} {
        font-size: 3.3rem;
      }
      color: ${({ textColor }) =>
        textColor ? textColor : "white"};
      };
    }

    .description {
      margin: 20px 0 35px 0;
      color: ${({ textColor }) =>
        textColor ? textColor : "white"};
      };
    }
`;

const Banner = ({
  banner_title,
  banner_background_image,
  banner_background_image_mobile,
  banner_description,
  banner_button_label,
  triggerFunction,
  textWidth,
  noOverlay,
  textColor,
}) => {
  const { func, arg } = triggerFunction || {};
  const [documentElement, setDocumentElement] = useState()

  useEffect(() => {
    const doc= window.document.createElement("div")
    doc.innerHTML = banner_description?.html
    setDocumentElement(doc)
  }, []);

  return (
    <Wrapper
      bgImageUrl={banner_background_image && banner_background_image.url}
      bgImageUrlMobile={
        banner_background_image_mobile && banner_background_image_mobile.url
      }
      isWithButton={banner_button_label}
      textWidth={textWidth}
      noOverlay={noOverlay}
      textColor={textColor}
    >
      <InnerWrapper className="inner-wrapper">
        <div className="txt-container">
          {banner_title && <h1 className="title">{banner_title.text}</h1>}
          {banner_description && (
            <div
              className="description description--banner"
              dangerouslySetInnerHTML={{ __html: `<p>${documentElement?.innerText}</p>` }}
            />
          )}

          {banner_button_label && (
            <Button onClick={() => func(arg)}>{banner_button_label}</Button>
          )}
        </div>
      </InnerWrapper>
    </Wrapper>
  );
};

export default Banner;
