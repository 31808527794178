// use for both link and button
import React from "react";
import styled from "styled-components";
import {
  mainBlack,
  mainWhite,
  transHover,
  screen,
} from "../variables/variables";
import { Link } from "gatsby";

const Wrapper = styled.div`
  max-width: max-content;

  a,
  button {
    background: ${mainWhite};
    border: 0;
    border-radius: 8px;
    color: ${mainBlack};
    cursor: pointer;
    padding: 8px 0;
    line-height: 20px;
    border: 1px ${mainWhite} solid;
    min-width: 200px;
    width: auto;
    display: block;
    text-align: center;
    font-weight: 600;
    transition: ${transHover};
    letter-spacing: 1px;
    @media ${screen.xsmall} {
      background: none;
      color: ${mainWhite};
      padding: 12px 0;
      min-width: 266px;
    }

    @media ${screen.withCursor} {
      &:hover {
        background: ${mainWhite};
        color: ${mainBlack};
      }
    }

    span {
      font-size: 0.875rem;
      @media ${screen.xsmall} {
        font-size: 1.075rem;
      }
    }
  }
`;

const Button = ({ to, className, children, onClick }) => {
  // if not link default to button tag
  const isLink = to;

  return (
    <Wrapper className={className}>
      {isLink ? (
        <Link to={to}>
          <span>{children || "LABEL"}</span>
        </Link>
      ) : (
        <button onClick={onClick}>
          <span>{children || "LABEL"}</span>
        </button>
      )}
    </Wrapper>
  );
};

export default Button;
