import React, { useRef, useState } from "react";
import styled from "styled-components";
import {
  screen
} from "../variables/variables";
import Polygon from "../../images/svg/polygon.svg";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.div`
    display: flex;
    gap: 30px;
    flex-direction: column;
    @media ${screen.small} {
        display: flex;
        justify-content: space-between;
        gap: 30px;
        flex-direction: row;
    }

    .collapses-info {
        max-width: 422px;

        .collapse-title {
            font-size: 30px;
            line-height: 40px;
            font-weight: bold;
            margin-bottom: 15px;
        }

        .collapse-dep {
            font-size: 20px;
            line-height: 30px;
            font-weight: 300;
        }
    }

    .collapses-list {
        margin-top: -16px;
        max-width: 736px;
    }
`;

const WrapperCollapse = styled.div`
    width: 100%;
    superscript {
      vertical-align: text-top;
      font-size: 12px;
      line-height: 22px;
    }
    .collapse-item--title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        cursor: pointer;
        border-radius: 10px;
        width: 100%;
        @media ${screen.xsmall} {
            width: auto;
        }
        &--text {
            padding: 10px;
            display: flex;
            align-items: center;
            gap: 40px;
            width: 100%;
            @media ${screen.xsmall} {
                width: auto;
            }
            .text {
                font-size: 14px;
                line-height: 17px;
                font-weight: bold;
                width: calc(100% - 98px);
                @media ${screen.xsmall} {
                    min-width: 240px;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
            .icon {
                width: 48px;
                height: 48px;
            }
        }
    }
    .collapse-item--content {
        padding: 10px;
        overflow: hidden;
        transition: all .6s;
        &--hidden {
            padding: 10px 0 20px;
            font-size: 16px;
            line-height: 26px;
            font-weight: 300;
        }
    }
`;

const CollapseItem = ({ title, content, iconURL, index  }) => {
  const [open, setOpen] = useState(false)
  const ref = useRef()
  return (
    <WrapperCollapse>
      <div className={'collapse-item--title'} onClick={() => setOpen(!open)}>
        <div className={'collapse-item--title--text'} style={{borderTop: index !== 0 ? '1px solid #fff' : ''}}>
          <img alt={''} className="icon" src={iconURL} />
          <div className={'text'}>
            {title}
          </div>
          <div style={{ transform: `rotate(${open ? 0 : 180}deg)` , transition: 'all .6s' }}>
            <Polygon />
          </div>
        </div>
      </div>
      <div className={"collapse-item--content"}  style={{
        height: open ? ref.current?.clientHeight + 30 : 0,
        borderTop: open ? '1px solid #fff' : '',
        marginTop: open ? '10px' : 0,
      }}>
        <div
          ref={ref} 
          className="collapse-item--content--hidden"
          dangerouslySetInnerHTML={{ __html: `<p>${content}</p>` }}
        />
      </div>
    </WrapperCollapse>
  )
}

const Collapse = ({ title, dep, collapses }) => {
  return (
    <Wrapper role="collapses">
      <div className='collapses-info'>
        <div className={'collapse-title'}>
          {title}
        </div>
        <div className={'collapse-dep'}>
          {dep}
        </div>
      </div>
      <div className='collapses-list'>
        {
          collapses.map((item, index) => {
            return (
              <CollapseItem
                key={index}
                index={index}
                title={item.repeatable_title.text}
                content={item.repeatable_content.text}
                iconURL={item.repeatable_icon.url}
              />
            )
          })
        }
      </div>
    </Wrapper>
  );
};

export default Collapse;
