import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import "../css/global.css";
import { motion } from "framer-motion";
import styled from "styled-components";
import InnerWrapper from "../components/inner-wrapper/inner-wrapper";
import AnimatedLogo from "../components/animated-logo/animated-logo";
import {
  mainBlack,
  mainWhite,
  screen,
} from "../components/variables/variables";
import Banner from "../components/banner/banner";
import Questions from "../components/Questions";
import Collapse from "../components/collapse";
import Button from "../components/button/button";
import { graphql } from "gatsby";

const Wrapper = styled.div`
  padding-top: 60px;
  superscript {
    vertical-align: text-top;
    font-size: 12px;
    line-height: 22px;
  }
  .intro {
    padding: 90px 0 45px 0;
    background-color: ${mainBlack};
    color: ${mainWhite};
    position: relative;
    z-index: 2;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 90px;
    
    .overlay {
      background: linear-gradient(180deg,#3c3c3c 0.00%,#2b5866 46.22%,#009ed0 100.00%);
      width: 100%;
      height: 35%;
      bottom: 0;
      left: 0;
      z-index: 1;
        position: absolute;
    }

    .col {
      .description {
        margin: 16px 0 0 0;
        @media ${screen.xsmall} {
          margin: 21px 0 0 0;
        }

        h2 {
          font-size: 1.2rem;
          font-weight: 700;
          margin: 38px 0 16px 0;
          @media ${screen.xsmall} {
            font-size: 1.4rem;
            margin: 52px 0 21px 0;
          }
        }

        strong {
          font-weight: 500;
        }

        a {
          color: ${mainBlack};
        }
      }
    }
  }

  .col {
    &--left {
      text-align: center;

      @media ${screen.small} {
        margin: 0 75px 0 0;
        max-width: 600px;
        text-align: left;
      }
      @media ${screen.medium} {
        margin: 0 150px 0 0;
      }
    }

    &--right {
      .btn {
        margin: 0 auto;
        @media ${screen.small} {
          margin: 0;
        }
      }
    }

    .description {
      margin: 0 0 26px 0;
      @media ${screen.small} {
        text-align: left;
        margin: 0;
      }

      a {
        font-weight: 600;
      }

      strong {
        font-weight: 600;
      }
    }
  }
    

  .cta {
      margin-bottom: 95px;
      .flex-row {
          position: relative;
          z-index: 2;
          filter: drop-shadow(0px 3px 15px rgba(0,0,0,0.21 ));
          background: #3c3c3c;
          padding: 30px 30px 36px 30px;
          border-radius: 12px;
          @media ${screen.xsmall} {
            padding: 35px 35px 40px 35px;
          }
          @media ${screen.small} {
            display: flex;
            padding: 35px 60px 40px 60px;
          }
        }

    .col {
    &--left {
      text-align: center;
      margin-bottom: 30px;
      @media ${screen.small} {
        margin: 0 75px 0 0;
        max-width: 600px;
        text-align: left;
      }
      @media ${screen.medium} {
        margin: 0 150px 0 0;
      }
    }

    &--right {
      flex: 1;
      .btn {
        margin: 0 auto;
        @media ${screen.small} {
          margin: 64px auto 0;
          max-width: 352px;
        }
        a {
            width: 100%;
            max-width: 352px;
        }
      }
    }

    .description {
      p {
        font-size: 16px;
        line-height: 26px;
        font-weight: 300;
      }

      strong {
        font-weight: 600;
      }
    }
  }
  }

  .process {
    background-color: ${mainBlack};
    color: ${mainWhite};
    padding: 165px 0 45px 0;
    @media ${screen.xsmall} {
      padding: 205px 0 90px 0;
    }

    .top-txt {
      max-width: 700px;
      margin: 0 0 26px 0;
      @media ${screen.xsmall} {
        margin: 0 0 40px 0;
      }
    }
  }
`;

const SolutionPageTemplate = ({ data }) => {
  const {
    banner_background_image_mobile,
    banner_background_image,
    cta_button_label,
    cta_description,
    cta_heading,
    body,
    main_title,
    banner_description,
    main_right_question,
    main_right_content,
    main_left_question,
    main_left_content
  } = data.content.data;

  return (
    <Layout>
      <Seo
        title={"Solution"}
        description={""}
      />
      <Wrapper>
        <Banner
          noOverlay
          banner_title={main_title}
          banner_description={banner_description}
          textColor={"#3C3C3C"}
          banner_background_image={banner_background_image}
          banner_background_image_mobile={banner_background_image_mobile}
        />
        <section className="intro">
          <div className={'overlay'}>
          </div>
          <InnerWrapper>
            <Questions data={[
              {
                question: main_left_question,
                answer: main_left_content.text
              },
              {
                question: main_right_question.text,
                answer: main_right_content.text
              }
            ]} />
          </InnerWrapper>

          {
            body.map((item, index) => {
              return (
                <InnerWrapper key={index}>
                  <Collapse
                    title={item.primary.non_repeatable_title.text}
                    dep={item.primary.non_repeatable_description.text}
                    collapses={item.items}
                  />
                </InnerWrapper>
              )
            })
          }
          <div className={'cta'}>
            <InnerWrapper>
              <div className="flex-row">
                <motion.div className="col col--left">
                  <h4 className="heading heading--semi-bold">
                    {cta_heading.text}
                  </h4>
                  <div
                    className="description"
                    dangerouslySetInnerHTML={{ __html: cta_description.html}}
                  />
                </motion.div>

                <motion.div className="col col--right">
                  <Button className="btn" to={'/about'}>
                    {cta_button_label}
                  </Button>
                </motion.div>
              </div>
            </InnerWrapper>
          </div>
          <AnimatedLogo className="logo-bg" />
        </section>

      </Wrapper>
    </Layout>
);
};

export default SolutionPageTemplate;

export const dataQuery = graphql`
  {
    content: prismicSolutionPageTemplate {
      id
      data {
        banner_background_image_mobile {
          url
        }
        banner_background_image {
          url
          thumbnails {
            og {
              url
            }
          }
        }
        banner_description {
          html
        }
        main_left_question 
        main_left_content {
          text
        }
        main_right_content {
          text
        }
        main_right_question {
          text
        }
        body {
          ... on PrismicSolutionPageTemplateDataBodyCardsCarousel {
            slice_type
            items {
              description {
                html
              }
              icon_image {
                alt
                url
              }
              subheading {
                text
              }
            }
            primary {
              description {
                html
              }
              heading {
                text
              }
              is_two_column
            }
          }
          ... on PrismicSolutionPageTemplateDataBodyImageAndList {
            slice_type
            items {
              subdescription
              subheading {
                text
              }
              icon_image {
                alt
                url
              }
            }
            primary {
              description {
                html
              }
              featured_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              heading {
                text
              }
            }
          }
          ... on PrismicSolutionPageTemplateDataBodyTextImage {
            slice_type
            primary {
              description {
                html
              }
              featured_image {
                alt
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              heading {
                text
              }
            }
          }
          ... on PrismicSolutionPageTemplateDataBodyMainCollapse {
            primary {
              non_repeatable_title {
                text
              }
              non_repeatable_description {
                text
              }
            }
            items {
              repeatable_title {
                text
              }
              repeatable_content {
                text
              }
              repeatable_icon {
                alt
                url
              }
            }
          }
        }
        cta_button_label
        cta_description {
          html
        }
        cta_heading {
          text
        }
        main_title {
          text
        }
        meta_description
        title_tag
      }
    }
  }
`;
